
const digest = '3ce0a0ce96e9174f445ff0f66ce3401d4209b8d8aa285a82e2f380e4872392bc';
const css = `._app_1jllt_1 {
  font-family: sans-serif;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"app":"_app_1jllt_1"};
export { css, digest };
  