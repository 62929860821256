
const digest = 'a2a7ffaee23f9e5ef80246b996e6a166d4f36cbcf6d9c9b393d64014ed535cb3';
const css = `._post_148tw_1 {
  border-bottom: grey 1px solid;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"post":"_post_148tw_1"};
export { css, digest };
  