import { AppAPI as API, APIDispatch } from "@alpaca-ooo/api";
import React from "react";

type Dispatch = APIDispatch<API.Schema>;
export const api = new Proxy({} as Dispatch, {
  get(target: Dispatch, apiName: keyof Dispatch) {
    return async (
      ...params: Parameters<Dispatch[typeof apiName]>
    ): Promise<ReturnType<Dispatch[typeof apiName]>> => {
      try {
        const response = await fetch(`/api/${apiName}`, {
          method: "POST", // always use POST for now to disable caching during debugging
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        });

        const responseContent = await response.text();

        if (!response.ok) {
          console.error(response);
          return {
            error: response.statusText,
            result: null,
          };
        }

        return {
          result: JSON.parse(responseContent || "{}"),
          error: null,
        };
      } catch (error: any) {
        console.error(error);
        return {
          result: null,
          error: error.message || "Unknown error",
        };
      }
    };
  },
});

export const useAPI = <T extends keyof API.Schema>(apiName: T) => {
  const [result, setResult] = React.useState<ReturnType<API.Schema[T]> | null>(
    null
  );
  const [error, setError] = React.useState<string | null>(null);

  const dispatch = api[apiName];

  const call = React.useCallback(
    (...params: Parameters<API.Schema[T]>) => {
      dispatch(...params).then((response) => {
        if (response.error) {
          setError(response.error);
        }

        setResult(response.result);
      });
    },
    [dispatch]
  );

  return { result, error, call };
};
