import React, { useEffect, useState } from "react";
import { api, useAPI } from "./utils/api";

import clsx from "clsx";
import styles from "./App.module.css";

const App = () => {
  const [selectedWorker, setSelectedWorker] = useState<string>();
  const [input, setInput] = useState<string>("");

  const { result: workers, call: getWorkers } = useAPI("getWorkers");
  const { result: posts, call: getPosts } = useAPI("getPosts");

  // run once on mount
  useEffect(() => {
    getWorkers();
    getPosts();
  }, []);

  // select default worker
  useEffect(() => {
    if (workers && workers.length > 0 && !selectedWorker) {
      setSelectedWorker(workers[0]);
    }
  }, [workers, selectedWorker]);

  return (
    <div className={styles.app}>
      <select
        id="worker"
        onChange={(worker) => {
          setSelectedWorker(worker.target.value);
        }}
      >
        {(workers || []).map((worker) => (
          <option key={worker} value={worker}>
            {worker}
          </option>
        ))}
      </select>
      <PostList
        posts={posts || []}
        onEdit={(postId, content) => {
          api.editPost({ postId, content }).then((response) => {
            // reload posts after editing
            response.result && getPosts();
          });
        }}
      />
      <input
        type="text"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
      <button
        onClick={() => {
          if (selectedWorker) {
            api
              .sendPost({ content: input, workerId: selectedWorker })
              .then((response) => {
                if (response.result) {
                  getPosts();
                }
              });

            setInput("");
          }
        }}
      >
        send
      </button>
    </div>
  );
};

import { createRoot } from "react-dom/client";
import { PostList } from "./component/PostList";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
