import { AppAPI as API } from "@alpaca-ooo/api";

import styles from "./PostList.module.css";

type PostProps = {
  posts: API.Post[];
  onEdit: (postId: number, content: string) => void;
};

export function PostList(props: PostProps) {
  return (
    <>
      {(props.posts || []).map((post) => (
        <div
          key={post.id}
          className={styles.post}
          onClick={() => {
            const newContent = prompt("Edit post", post.content);
            if (newContent) {
              props.onEdit(post.id, newContent);
            }
          }}
        >
          {post.author}: {post.content}
        </div>
      ))}
    </>
  );
}
